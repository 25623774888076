<template>
  <div>
    <v-card
      outlined
      class="loginCard d-flex justify-center align-center px-4 px-md-8 px-lg-8 py-4"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          class="py-4 pr-0 pl-0 pr-md-4 pr-sm-4 pr-lg-4 d-flex"
        >
          <v-layout align-center class="text-sm-left" v-if="logo">
            <v-img
              contain
              class="logoLogin"
              :src="serverUrl + '/images/' + logo + '?token=' + userToken"
            >
            </v-img>
          </v-layout>
          <v-layout align-center class="text-sm-left" v-else>
            <v-img
              contain
              class="logoLogin"
              :src="getLogo"
              v-on:error="failedImage = true"
            ></v-img>
          </v-layout>
        </v-col>
        <v-col cols="12" sm="6" class="pl-0 pl-sm-4 pl-md-4 pl-lg-4 pr-0">
          <languageSelector
            :addContainer="true"
            :cssProps="'language-selector-in-row max-w-select-lang-login'"
          />
          <v-form
            class="mt-6"
            @submit.prevent="login"
            ref="form"
            lazy-validation
          >
            <v-text-field
              outlined
              dense
              :label="$t('Email')"
              v-model="email"
              required
              :rules="getRules('notEmptyEmailRule')"
              prepend-inner-icon="mdi-email"
              validate-on-blur
              hide-details="auto"
              class="mb-4"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              :label="$t('Password')"
              v-model="pass"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              prepend-inner-icon="mdi-lock"
              required
              :rules="[
                (v) => !!v || $t('Password is required'),
                (v) => (v && v.length > 4) || $t('Password is too short'),
              ]"
              validate-on-blur
              hide-details="auto"
            ></v-text-field>

            <div class="text-right">
              <p class="text-right mt-2 mb-4 text-caption font-weight-black">
                <router-link to="/forgotpassword">
                  {{ $t("Forgot password?") }}
                </router-link>
              </p>
              <v-btn
                depressed
                type="submit"
                :loading="loading"
                class="ml-0 mb-4 mr-0 mt-4"
                color="primary"
                >{{ $t("Login") }}</v-btn
              >
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
    <p class="text-center mt-2 pt-2 text-caption mr-1">
      <span class="ml-1">
        {{ $t("No account yet?") }}
      </span>
      <router-link to="/requestaccess" class="font-weight-black">
        {{ $t("Request access") }}
      </router-link>
    </p>
  </div>
</template>

<script>
import validation from "../../validation";

export default {
  data() {
    return {
      email: "",
      pass: "",
      loading: false,
      logo: this.$config.logoPos,
      serverUrl: this.$helpers.getServerUrl(),
      failedImage: false,
      userToken: this.$auth.userToken(),
      showPassword: false,
    };
  },
  computed: {
    getLogo: {
      get() {
        return this.failedImage
          ? this.serverUrl +
              "/images/" +
              "logo-pos.png" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate()
          : this.serverUrl +
              "/images/" +
              "logo-pos.svg" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate();
      },
    },
  },

  methods: {
    getRules(rule) {
      return validation.getValidation(rule);
    },
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$auth.login(this.email, this.pass, (res) => {
          this.$request.get("/websites", null, (websitesResponse) => {
            this.loading = false;
            if (!res.success) {
              const toaster = {
                message: res.message,
                timeout: false,
                type: "ERROR",
                toastClassName: ["toaster-color-error", "toaster-layout"],
                bodyClassName: ["toaster-text-layout"],
                icon: "mdi-alert-octagon",
              };
              this.$store.dispatch("setToaster", toaster);
              this.pass = "";
            } else {
              this.$store.dispatch("setAllWebsites", websitesResponse.data);
              this.$i18n.set(res.data.Language);
              let hasItem = false;
              websitesResponse.data[0].Pages.forEach((element) => {
                if (
                  element._id.$oid ==
                  websitesResponse.data[0].DashboardPageId.$oid
                ) {
                  hasItem = true;
                }
              });

              if (
                websitesResponse.data[0].DashboardPageId.$oid !==
                "000000000000000000000000"
              ) {
                if (!hasItem) {
                  //pushing route
                  this.$router.push({
                    name: "editPage",
                    params: {
                      id: websitesResponse.data[0].Pages[0]._id.$oid,
                    },
                  });
                  this.$router.go(1);
                } else {
                  //pushing route
                  this.$router.replace({
                    name: "editPage",
                    params: {
                      id: websitesResponse.data[0].DashboardPageId.$oid,
                    },
                  });
                  this.$router.go(1);
                }
              } else {
                this.$router.replace({ name: "dashboard" });
                this.$router.go(1);
              }
            }
          });
        });
      }
    },
    getDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return mm + dd + yyyy;
    },
  },
};
</script>

<style scoped>
.loginCard {
  margin-top: 40px;
  height: 400px;
}

.logoLogin {
  max-width: 100%;
  width: 85%;
  margin: auto;
}

@media only screen and (min-width: 768px) {
  .loginCard {
    margin-top: 40px;
    height: 350px;
  }
}
@media only screen and (max-width: 740px) {
  .logoLogin {
    max-width: 100%;
    width: 85%;
    margin: auto;
    height: 50px;
  }
}
</style>
